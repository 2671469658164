<template>
  <v-container fluid>

    <v-row dense>
      <!-- Facilities -->
      <v-col cols="12" md="6">
        <v-card height="42vh" elevation="5" class="overflow-hidden" :loading="facilitiesTableLoader">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img :src="organizationsBlack" class="ml-2" max-height="21" max-width="22"></v-img>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('organization-facilities')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="facilitySearch"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div>
            <v-data-table
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :items="filteredBillings"
                :headers="facilityHeaders"
                item-key="patientId"
                fixed-header
                :loading="loader"
                dense
                :search="facilitySearch"
                ref="table"
                :single-select="true"
                class="mx-0 px-0 medical-staff-data-table pointer"
                height="37vh"
                max-height="380"
                group-by="hospitalData.id"
                :single-expand="true"
                :items-per-page="-1"
                hide-default-footer
            >
              <template v-slot:[`group.header`]="{ headers, items, toggle }">
                <td
                    @click="toggle"
                    class="elevation-1 px-1 py-0 white-background"
                    :colspan="facilityHeaders.length"
                >
                  <v-row class="px-0" dense @click="handleFacilityClick(items[0])">
                    <v-col class="text-left font-weight-medium font-size12-5 ml-3" cols="3" v-if="items && items.length > 0">
                      {{ items[0].hospitalData.hospitalName }}
                    </v-col>
                    <v-col class="text-left px-0 mx-0 font-size12-5">
                      {{ findBillingUnitsbyHospitalId(items[0].hospitalId).BillingUnits99454 }}/{{ findBillingUnitsbyHospitalId(items[0].hospitalId).userCount }}
                    </v-col>
                    <v-col class="text-left  px-0 mx-0 font-size12-5">
                    {{ findBillingUnitsbyHospitalId(items[0].hospitalId).BillingUnits99457 }}/{{ findBillingUnitsbyHospitalId(items[0].hospitalId).userCount }}
                    </v-col>
                    <v-col class="text-left px-0 mx-0 font-size12-5">
                    {{ findBillingUnitsbyHospitalId(items[0].hospitalId).BillingUnits994581st }}/{{ findBillingUnitsbyHospitalId(items[0].hospitalId).userCount }}
                    </v-col>
                    <v-col class="text-left px-0 mx-0 font-size12-5">
                    {{ findBillingUnitsbyHospitalId(items[0].hospitalId).BillingUnits994582nd }}/{{ findBillingUnitsbyHospitalId(items[0].hospitalId).userCount }}
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template v-slot:item="{ item, index }">
                <tr style="height:7px !important;" v-if="index === 0">
                  <td
                      class="color-header text-left"
                      v-for="(header, index) in insideHeaders"
                      :key="index"
                      style="height:7px !important;"
                      :colspan="1"
                  >
                    <span class="font-weight-medium font-size11">{{ header.text }}</span>
                  </td>
                </tr>
                <tr @click="handlePatientClick(item)">
                  <td class="text-left font-size12 font-weight-medium" >
                    {{ item.patientData?.firstName }} {{ item.patientData?.lastName }}
                  </td>
                  <td class="text-left font-size12 font-weight-medium" >
                    <v-icon size="16" class="mr-2" v-if="item.myBillings?.m99454IsBillable" color="black">mdi-checkbox-marked-outline</v-icon>
                    <v-icon size="16" class="mr-2" v-else color="black">mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-left font-size12 font-weight-medium" >
                    <v-icon size="16" class="mr-2" v-if="item.myBillings?.m99457IsBillable" color="black">mdi-checkbox-marked-outline</v-icon>
                    <v-icon size="16" class="mr-2" v-else color="black">mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-left font-size12 font-weight-medium" >
                    <v-icon size="16" class="mr-2" v-if="item.myBillings?.m994581stIsBillable" color="black">mdi-checkbox-marked-outline</v-icon>
                    <v-icon size="16" class="mr-2" v-else color="black">mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-left font-size12 font-weight-medium">
                    <v-icon size="16" class="mr-2" v-if="item.myBillings?.m994582ndIsBillable" color="black">mdi-checkbox-marked-outline</v-icon>
                    <v-icon size="16" class="mr-2" v-else color="black">mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td></td>
                  <td></td>
<!--                  <td :colspan="1">-->
                    <!--
                    <v-tooltip bottom color="primary2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="blue"
                          class="float-right mr-n2 mt-1"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{$t('view-patient-details')}}</span>
                    </v-tooltip>
                    -->
<!--                  </td>-->
                </tr>
              </template>
            </v-data-table>

          </div>
        </v-card>
        <v-card height="40vh" elevation="5" class="overflow-auto" :loading="facilitiesTableLoader">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img :src="info" class="ml-2" max-height="21" max-width="22"></v-img>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('organization-users-status-and-billing')}}</div>
            </div>
          </div>
          <v-card-text>
              <v-card class="d-flex align-center justify-center full-width py-1">
                <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                  {{ $t('active') }}
                  <v-avatar
                      color="#09CB09"
                      size="30">
                    <span class="white--text">{{ organizationUserStatus.activeUsers }}</span>
                  </v-avatar>
                </div>
                <!--              INACTIVE-->
                <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                  {{ $t('inactive') }}
                  <v-avatar
                      color="gray"
                      size="30"
                  >
                    <span class="black--text">{{ organizationUserStatus.inactiveUsers }}</span>
                  </v-avatar>
                </div>
                <!--              DECEASED-->
                <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                {{ $t('deceased') }}
                <v-avatar
                    color="#000"
                    size="30"
                >
                  <span class="white--text">{{ organizationUserStatus.deceasedUsers }}</span>
                </v-avatar>
              </div>
              </v-card>
              <v-card class="d-flex align-center justify-center full-width">
                <v-card-text>
                  <div class="text-center my-0 py-0 mb-2">
                    <span class="font-size16 font-weight-bold black--text" >{{$t('billings')}}:  {{ authUserData.organizationAssignedData.institutionName }}</span>
                  </div>
                  <div class="d-flex justify-center">
                    <v-progress-circular
                        class="font-size12 mx-2 px-2 font-weight-bold"
                        :rotate="-90"
                        :size="80"
                        :width="2"
                        :value="organizationBillingUnits.BillingUnits99454Percent"
                        :color="organizationBillingUnits.BillingUnits99454Percent  >= 100 ? 'green' : 'primary'"
                    >
                      99454 {{ organizationBillingUnits.BillingUnits99454}}/{{organizationBillingUnits.userCount}}
                    </v-progress-circular>
                    <v-progress-circular
                        class="font-size12 mx-2 px-2 font-weight-bold"
                        :rotate="-90"
                        :size="80"
                        :width="2"
                        :value="organizationBillingUnits.BillingUnits99457Percent"
                        :color="organizationBillingUnits.BillingUnits99457Percent >= 100 ? 'green' : 'primary'"
                    >
                      99457 {{ organizationBillingUnits.BillingUnits99457}}/{{organizationBillingUnits.userCount}}
                    </v-progress-circular>
                    <v-progress-circular
                        class="font-size12 mx-2 px-2 font-weight-bold"
                        :rotate="-90"
                        :size="80"
                        :width="2"
                        :value="organizationBillingUnits.BillingUnits994581stPercent"
                        :color="organizationBillingUnits.BillingUnits994581stPercent ? 'green' : 'primary'"
                    >
                      99458 1st {{ organizationBillingUnits.BillingUnits994581st }}/{{organizationBillingUnits.userCount}}
                    </v-progress-circular>
                    <v-progress-circular
                        class="font-size12 mx-2 font-weight-bold"
                        :rotate="-90"
                        :size="80"
                        :width="2"
                        :value="organizationBillingUnits.BillingUnits994582ndPercent"
                        :color="organizationBillingUnits.BillingUnits994582ndPercent >= 100 ? 'green' : 'primary'"
                    >
                      99458 2nd {{ organizationBillingUnits.BillingUnits994582nd }}/{{organizationBillingUnits.userCount}}
                    </v-progress-circular>
                  </div>
                  <v-divider class="mt-3"></v-divider>
                  <v-row dense class="d-flex justify-center mt-5" justify="space-around">
                    <v-col cols="12">
                      <v-card>
                        <div class="d-flex align-center pa-2 my-n5">
                          <v-progress-linear
                              class="white--text"
                              striped
                              :value="getOverAllProgressForOrganization().toFixed(1)"
                              :color="progressColorForOrganization"
                              height="25">
                            {{$t('progress')}}: {{ getOverAllProgressForOrganization().toFixed(1) }} %
                          </v-progress-linear>
                        </div>

                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Billings -->
      <v-col cols="6" >
        <v-card height="42vh" elevation="5" class="overflow-auto" >
          <div class="d-flex align-center elevation-3 py-3" >
            <v-img class="ml-1" :src="billingsNew" max-height="21" max-width="22"></v-img>
            <div class="text-left font-weight-bold font-size16 ml-1 ">{{$t('facility-users-status-and-billing')}}</div>
          </div>
          <v-card-text>
            <v-card class="d-flex align-center justify-center full-width py-1">
                <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                  {{ $t('active') }}
                  <v-avatar
                      color="#09CB09"
                      size="30">
                    <span class="white--text">{{ facilityUserStatus.activeUsers ? facilityUserStatus.activeUsers : 0 }}</span>
                  </v-avatar>
                </div>
                <!--              INACTIVE-->
                <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                  {{ $t('inactive') }}
                  <v-avatar
                      color="gray"
                      size="30"
                  >
                    <span class="black--text">{{ facilityUserStatus.inactiveUsers ? facilityUserStatus.inactiveUsers : 0 }}</span>
                  </v-avatar>
                </div>
                <!--              DECEASED-->
                <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                  {{ $t('deceased') }}
                  <v-avatar
                      color="#000"
                      size="30"
                  >
                    <span class="white--text">{{ facilityUserStatus.deceasedUsers ? facilityUserStatus.deceasedUsers : 0 }}</span>
                  </v-avatar>
                </div>
              </v-card>
            <v-card>
              <v-card-text>
                <div class="text-center my-0 py-0 mb-2">
                  <span class="font-size16 font-weight-bold black--text" >{{$t('billings')}}: {{ facilityClicked ? hospitalName : `${patientDataObject.patientData?.firstName} ${patientDataObject.patientData?.lastName || ''}` }}</span>
                </div>
                <div class="d-flex justify-center">
                  <v-progress-circular
                      v-if="facilityClicked"
                      class="font-size12 mx-2 px-2 font-weight-bold"
                      :rotate="-90"
                      :size="80"
                      :width="1"
                      :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99454 : null"
                      :color="getHospitalSummary(this.clickedFacilityId)?.percent99454 >= 100 ? 'green' : 'primary'"
                  >
                    <div class="d-flex flex-column">
                      <span>
                        99454
                      </span>
                      <div>
                        {{ getHospitalSummary(this.clickedFacilityId)?.percent99454 || '0' }}%
                      </div>
                    </div>

                  </v-progress-circular>
                  <v-progress-circular
                      v-if="facilityClicked"
                      class="font-size12 mx-2 px-2 font-weight-bold"
                      :rotate="-90"
                      :size="80"
                      :width="1"
                      :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99457 : ''"
                      :color="getHospitalSummary(this.clickedFacilityId)?.percent99457 >= 100 ? 'green' : 'primary'"
                  >
                    <div class="d-flex flex-column">
                      <span>
                        99457
                      </span>
                      <div>
                        {{ getHospitalSummary(this.clickedFacilityId)?.percent99457 || '0' }}%
                      </div>
                    </div>
                  </v-progress-circular>
                  <v-progress-circular
                      v-if="facilityClicked"
                      class="font-size12 mx-2 px-2 font-weight-bold"
                      :rotate="-90"
                      :size="80"
                      :width="1"
                      :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent994581st : ''"
                      :color="getHospitalSummary(this.clickedFacilityId)?.percent994581st >= 100 ? 'green' : 'primary'"
                  >
                    <div class="d-flex flex-column">
                      <span>
                     99458 1st
                      </span>
                      <div>
                        {{ getHospitalSummary(this.clickedFacilityId)?.percent994581st || '0' }}%
                      </div>
                    </div>
                  </v-progress-circular>
                  <v-progress-circular
                      v-if="facilityClicked"
                      class="font-size12 mx-2 font-weight-bold"
                      :rotate="-90"
                      :size="80"
                      :width="1"
                      :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId)?.percent994582nd : ''"
                      :color="getHospitalSummary(this.clickedFacilityId)?.percent994582nd >= 100 ? 'green' : 'primary'"
                  >
                    <div class="d-flex flex-column">
                      <span>
                    99458 2nd
                      </span>
                      <div>
                        {{ getHospitalSummary(this.clickedFacilityId)?.percent994582nd || '0' }}%
                      </div>
                    </div>
                  </v-progress-circular>
                  <v-progress-circular
                      v-if="clickedPatient"
                      :rotate="-90"
                      :size="80"
                      :width="2"
                      :value="patientDataObject.myBillings.percent99454"
                      :color="patientDataObject.myBillings.percent99454 === 100 ? 'green' : 'primary'"
                      class="font-size12 px-2 font-weight-bold"
                  >
                    <div v-if="patientDataObject.myBillings.m99454IsBillable">
                      <v-icon size="16" class="mr-2 " color="black">mdi-checkbox-marked-outline</v-icon>
                    </div>
                    <div class="d-flex flex-column">
                      <span>99454</span>
                      <div>{{patientDataObject.myBillings.totalMeasurements99454}}/16</div>
                    </div>

                  </v-progress-circular>
                  <v-progress-circular
                      v-if="clickedPatient"
                      :rotate="-90"
                      :size="80"
                      :width="2"
                      :value="patientDataObject.myBillings.percent99457"
                      :color="patientDataObject.myBillings.percent99457 === 100 ? 'green' : 'primary'"
                      class="font-size12 mx-3 px-2 font-weight-bold"
                  >
                    <div class="d-flex flex-column">
                      <span>
                        99457
                      </span>
                      <span>
                     {{ secToMinV2(patientDataObject.myBillings.seconds99457) }}
                      </span>
                    </div>

                  </v-progress-circular>
                  <v-progress-circular
                      v-if="clickedPatient"
                      :rotate="-90"
                      :size="80"
                      :width="2"
                      :value="patientDataObject.myBillings.percent994581st"
                      :color="patientDataObject.myBillings.percent994581st === 100 ? 'green' : 'primary'"
                      class="font-size11  px-2 font-weight-bold"
                  >
                    <div class="d-flex flex-column">
                      <span>
                       99458 1st
                      </span>
                      <span>
                    {{ secToMinV2(patientDataObject.myBillings.seconds994581st) }}
                      </span>
                    </div>
                  </v-progress-circular>
                  <v-progress-circular
                      v-if="clickedPatient"
                      :rotate="-90"
                      :size="80"
                      :width="2"
                      :value="patientDataObject.myBillings?.percent994582nd"
                      :color="patientDataObject.myBillings?.percent994582nd === 100 ? 'green' : 'primary'"
                      class="font-size11 mx-3 px-2  font-weight-bold"
                  >
                    <div class="d-flex flex-column">
                    <span>
                       99458 2nd
                    </span>
                      <span>
                        {{ secToMinV2(patientDataObject.myBillings.seconds994582nd) }}
                      </span>
                    </div>
                  </v-progress-circular>
                </div>
                <v-divider class="mt-3"></v-divider>
                <v-row dense class="d-flex justify-center mt-5" justify="space-around">
                  <v-col cols="12">
                    <v-card>
                      <div class="d-flex align-center pa-2 my-n5">
                        <v-progress-linear
                            class="white--text"
                            striped
                            :value="getOverAllProgressFor(clickedFacilityId).toFixed(1)"
                            :color="progressColor"
                            height="25">
                          {{$t('progress')}}: {{ getOverAllProgressFor(clickedFacilityId).toFixed(1) }} %
                        </v-progress-linear>
                      </div>

                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card height="40vh" elevation="5" class="overflow-hidden">
          <div class="d-flex align-center elevation-3 py-1" >
            <v-img class="ml-1" :src="info" max-height="21" max-width="22"></v-img>
            <div class="text-left font-weight-bold font-size15 ml-1 ">{{$t('organization-revenue')}} : {{ authUserData.organizationAssignedData.institutionName }}</div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div style="display: flex; flex-direction: column;">
              <Graph ref="graphSeriesOrganization" type="bar" :options="chartOptionsOrganization" :series="graphSeriesOrganization"></Graph>
            </div>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { billBlack, organizationsBlack, info } from '@/assets';
import Graph from 'vue-apexcharts';

export default {
  components: { Graph },
  data () {
    return {
      graphSeriesOrganization: [{
        name: this.$t('revenue'),
        data: [],
      }],
      chartOptionsOrganization: {
        chart: {
          type: 'bar',
          height: 250,
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            // Format the value as currency
            return `${value.toFixed(0)}%`;
          },
          style: {
            fontSize: '12px',
            colors: ['#000'],
          },
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: '',
          align: 'left',
        },
        xaxis: {
          type: 'category',
          labels: {
            // rotate: -90, // Rotate x-axis labels
            // style: {
            //   fontSize: '12px',
            //   fontFamily: 'Arial, sans-serif',
            // },
          },
        },
        yaxis: {
          opposite: false,
          min: 0,
          labels: {
          },
        },
        legend: {
          horizontalAlign: 'left',
        },
      },
      hospitalName: null,
      hospitalData: [],
      expanded: [],
      billingsNew: billBlack,
      info: info,
      clickedPatient: false,
      patientDataObject: null,
      hospitalSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      facilitySearch: '',
      facilitiesTableLoader: false,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      sortBy: 'noteTimestamp',
      sortDesc: true,
      loader: false,
      lastValidHospitalId: null,
      billingSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      clickedFacilityId: null,
      insideHeaders: [
        { text: this.$t('patient-name'), value: 'medicalNoteCategoryName', sortable: true, class: 'primary', align: 'start' },
        // { text: this.$t('age'), sortable: false, class: 'color-header', align: 'start' },
        { text: '99454', sortable: false, class: 'color-header', align: 'start' },
        { text: '99457', sortable: false, class: 'color-header', align: 'start' },
        { text: '994581st', sortable: false, class: 'color-header', align: 'start' },
        { text: '9945782nd', sortable: false, class: 'color-header', align: 'start' },
        { text: this.$t(''), sortable: false, class: 'color-header', align: 'start' },
        { text: this.$t(''), sortable: false, class: 'color-header', align: 'start' },
      ],
      facilityHeaders: [
        { text: this.$t('hospital-name'), value: 'hospitalData.hospitalName', align: 'start', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },
        { text: this.$t('billing-codes'), align: 'start', value: '', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },

      ],
      facilityClicked: true,
      organizationBillingUnits: {},
      selectedFacilityBillingUnits: {},
      allHospitalsBillingUnitsData: [],
      facilityUserStatus: {},
      organizationUserStatus: {},
    };
  },
  computed: {
    ...mapState({
      selectedMonthAndYear: (state) => state.filterbar.billingDate,
      authUserData: (state) => state.authentication.userData,
      billingsFromRepo: state => state.billings.billingSummaryForMonth,
    }),

    ...mapGetters({
      pageHeight: 'pageHeight',
      roleName: 'authentication/getRole',
      userSettings: 'authentication/getUserSettings',
    }),
    progressColor () {
      const progress = this.getOverAllProgressFor(this.clickedFacilityId).toFixed(1);
      if (progress < 75) {
        return 'red'; // Color for progress less than 50
      } else if (progress >= 75 && progress < 90) {
        return 'orange'; // Color for progress between 50 and 75
      } else {
        return 'green'; // Color for progress 75 and above
      }
    },
    progressColorForOrganization () {
      const progress = this.getOverAllProgressForOrganization().toFixed(1);
      if (progress < 75) {
        return 'red'; // Color for progress less than 50
      } else if (progress >= 75 && progress < 90) {
        return 'orange'; // Color for progress between 50 and 75
      } else {
        return 'green'; // Color for progress 75 and above
      }
    },
    organizationsBlack () {
      return organizationsBlack;
    },
    filteredBillings () {
      const searchTerms = this.facilitySearch.toLowerCase().split(' ');
      return this.billingsFromRepo.filter(item => {
        const email = item.patientData?.email.toLowerCase();
        const firstName = item.patientData?.firstName.toLowerCase();
        return searchTerms.every(term =>
            email?.includes(term) ||
            firstName?.includes(term),
        );
      });
    },
  },
  async created () {
    await this.getbillingsummaryforallpatientsformonth();
  },
  async mounted () {
    this.facilitiesTableLoader = true;
    this.toggleHeaders();
    this.facilitiesTableLoader = false;
  },
  watch: {
    selectedMonthAndYear (val) {
      if (val) {
        this.handleFacilityClick();
        this.getbillingsummaryforallpatientsformonth();
      }
    },
  },
  methods: {
    getOverAllProgressFor (selectedHospitalId) {
      var result = ((this.getHospitalSummary(selectedHospitalId)?.percent99454 * (1 / 4)) + (this.getHospitalSummary(selectedHospitalId)?.percent99457 * (1 / 4)) +
          (this.getHospitalSummary(selectedHospitalId)?.percent994581st * (1 / 4)) + (this.getHospitalSummary(selectedHospitalId)?.percent994582nd * (1 / 4)));
      if (isNaN(result)) result = 0;

      return result;
    },

    getOverAllProgressForOrganization () {
      var result = this.organizationBillingUnits.BillingUnits99454Percent * 0.25 +
          this.organizationBillingUnits.BillingUnits99457Percent * 0.25 +
          this.organizationBillingUnits.BillingUnits994581stPercent * 0.25 +
          this.organizationBillingUnits.BillingUnits994582ndPercent * 0.25;
      if (isNaN(result)) result = 0;
      return result;
    },
    async handleFacilityClick (val) {
      this.hospitalName = val?.hospitalData?.hospitalName;
      // this.medicalStaffTableLoader = true;
      this.clickedPatient = false;
      this.facilityClicked = true;
      if (this.facilityClicked === true) {
        this.clickedPatient = false;
      }
      if (this.clickedPatient === true) {
        this.facilityClicked = false;
      }
      this.medicalStaffClicked = false;
      if (val === undefined) {
        if (this.lastValidHospitalId !== null) {
          this.clickedFacilityId = this.lastValidHospitalId;
        } else {
          return;
        }
      } else {
        this.clickedFacilityId = val.hospitalId;
        this.lastValidHospitalId = val.hospitalId;
      }
      this.medicalStaffTableLoader = true;
      // await this.getbillingsummaryforallpatientsformonth();
      // this.calculateBillingUnitsForFacility();
      this.findBillingUnitsbyHospitalId(this.clickedFacilityId);
      this.calculatePatientStatusForFacility();
    },

    calculateBillingUnitsForOrganization () {
      // Count based on the value of the properties if they are true
      const userCount = this.billingsFromRepo.length;

      this.organizationBillingUnits = this.billingsFromRepo.reduce((acc, item) => {
        if (item.myBillings.m99454IsBillable) acc.BillingUnits99454++;
        if (item.myBillings.m99457IsBillable) acc.BillingUnits99457++;
        if (item.myBillings.m994581stIsBillable) acc.BillingUnits994581st++;
        if (item.myBillings.m994582ndIsBillable) acc.BillingUnits994582nd++;
        return acc;
      }, { BillingUnits99454: 0, BillingUnits99457: 0, BillingUnits994581st: 0, BillingUnits994582nd: 0 });

      this.organizationBillingUnits.userCount = userCount;
      this.organizationBillingUnits.BillingUnits99454Percent = (userCount > 0) ? ((this.organizationBillingUnits.BillingUnits99454 / userCount) * 100).toFixed(1) : 0;
      this.organizationBillingUnits.BillingUnits99457Percent = (userCount > 0) ? ((this.organizationBillingUnits.BillingUnits99457 / userCount) * 100).toFixed(1) : 0;
      this.organizationBillingUnits.BillingUnits994581stPercent = (userCount > 0) ? ((this.organizationBillingUnits.BillingUnits994581st / userCount) * 100).toFixed(1) : 0;
      this.organizationBillingUnits.BillingUnits994582ndPercent = (userCount > 0) ? ((this.organizationBillingUnits.BillingUnits994582nd / userCount) * 100).toFixed(1) : 0;
      this.calculateBillingUnitsForFacilityV2();
    },

    calculatePatientStatusForFacility () {
      // Filter by hospitalId
      const filteredUsersByHospital = this.billingsFromRepo.filter(item => item.hospitalId === this.clickedFacilityId);

      // Count based on the value of the properties if they are true
      this.facilityUserStatus = filteredUsersByHospital.reduce((acc, item) => {
        if (item.patientData !== null && item.patientData !== undefined) {
          if (item.patientData.status === 1) acc.activeUsers++;
          if (item.patientData.status !== 1 && item.patientData.status !== 99) acc.inactiveUsers++;
          if (item.patientData.status === 99) acc.deceasedUsers++;
        }
        return acc;
      }, { activeUsers: 0, inactiveUsers: 0, deceasedUsers: 0 });
    },

    calculateBillingUnitsForFacility () {
      // Filter by hospitalId
      const filteredByHospital = this.billingsFromRepo.filter(item => item.hospitalId === this.clickedFacilityId);
      const userCount = filteredByHospital.length;

      // Count based on the value of the properties if they are true
      this.selectedFacilityBillingUnits = filteredByHospital.reduce((acc, item) => {
        if (item.myBillings.m99454IsBillable) acc.BillingUnits99454++;
        if (item.myBillings.m99457IsBillable) acc.BillingUnits99457++;
        if (item.myBillings.m994581stIsBillable) acc.BillingUnits994581st++;
        if (item.myBillings.m994582ndIsBillable) acc.BillingUnits994582nd++;
        return acc;
      }, { BillingUnits99454: 0, BillingUnits99457: 0, BillingUnits994581st: 0, BillingUnits994582nd: 0 });

      this.selectedFacilityBillingUnits.userCount = userCount;

      this.selectedFacilityBillingUnits.BillingUnits99454Percent = (userCount > 0) ? ((this.selectedFacilityBillingUnits.BillingUnits99454 / userCount) * 100).toFixed(1) : 0;
      this.selectedFacilityBillingUnits.BillingUnits99457Percent = (userCount > 0) ? ((this.selectedFacilityBillingUnits.BillingUnits99457 / userCount) * 100).toFixed(1) : 0;
      this.selectedFacilityBillingUnits.BillingUnits994581stPercent = (userCount > 0) ? ((this.selectedFacilityBillingUnits.BillingUnits994581st / userCount) * 100).toFixed(1) : 0;
      this.selectedFacilityBillingUnits.BillingUnits994582ndPercent = (userCount > 0) ? ((this.selectedFacilityBillingUnits.BillingUnits994582nd / userCount) * 100).toFixed(1) : 0;
    },

    calculateBillingUnitsForFacilityV2 () {
      // Get unique hospital IDs
      const uniqueHospitalIds = [...new Set(this.billingsFromRepo.map(item => item.hospitalId))];

    // Initialize an array to store the results for all hospitals
    const allHospitalsBillingData = [];

    // Iterate through each unique hospital ID
      uniqueHospitalIds.forEach(hospitalId => {
        const filteredByHospital = this.billingsFromRepo.filter(item => item.hospitalId === hospitalId);
        const userCount = filteredByHospital.length;

        // Calculate billing units for the current hospital
        const selectedFacilityBillingUnits = filteredByHospital.reduce((acc, item) => {
          if (item.myBillings.m99454IsBillable) acc.BillingUnits99454++;
          if (item.myBillings.m99457IsBillable) acc.BillingUnits99457++;
          if (item.myBillings.m994581stIsBillable) acc.BillingUnits994581st++;
          if (item.myBillings.m994582ndIsBillable) acc.BillingUnits994582nd++;
          return acc;
        }, { BillingUnits99454: 0, BillingUnits99457: 0, BillingUnits994581st: 0, BillingUnits994582nd: 0 });

        // Add user count and percentages
        selectedFacilityBillingUnits.userCount = userCount;
        selectedFacilityBillingUnits.BillingUnits99454Percent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits99454 / userCount) * 100).toFixed(1) : 0;
        selectedFacilityBillingUnits.BillingUnits99457Percent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits99457 / userCount) * 100).toFixed(1) : 0;
        selectedFacilityBillingUnits.BillingUnits994581stPercent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits994581st / userCount) * 100).toFixed(1) : 0;
        selectedFacilityBillingUnits.BillingUnits994582ndPercent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits994582nd / userCount) * 100).toFixed(1) : 0;

        // Add the hospital ID to the result
        selectedFacilityBillingUnits.hospitalId = hospitalId;

        // Store the result in the array
        allHospitalsBillingData.push(selectedFacilityBillingUnits);
      });

    // Now you have all the billing data for all hospitals in allHospitalsBillingData

    // If you need to set it to a component data property, do it here
      this.allHospitalsBillingUnitsData = allHospitalsBillingData;
    },
    findBillingUnitsbyHospitalId (hospitalId) {
      // return this.allHospitalsBillingUnitsData.find(item => item.hospitalId === hospitalId);
        return this.allHospitalsBillingUnitsData.find(item => item.hospitalId === hospitalId) || {
          BillingUnits99454: 0,
          BillingUnits99457: 0,
          BillingUnits994581st: 0,
          BillingUnits994582nd: 0,
          userCount: 0,
          BillingUnits99454Percent: '0.0',
          BillingUnits99457Percent: '0.0',
          BillingUnits994581stPercent: '0.0',
          BillingUnits994582ndPercent: '0.0',
          hospitalId: hospitalId,
        };
    },
    calculateBillingUnitsForGivenFacilityId (facilityId) {
      // Filter by hospitalId
      const filteredByHospital = this.billingsFromRepo.filter(item => item.hospitalId === facilityId);
      const userCount = filteredByHospital.length;

      // Count based on the value of the properties if they are true
      var selectedFacilityBillingUnits = filteredByHospital.reduce((acc, item) => {
        if (item.myBillings.m99454IsBillable) acc.BillingUnits99454++;
        if (item.myBillings.m99457IsBillable) acc.BillingUnits99457++;
        if (item.myBillings.m994581stIsBillable) acc.BillingUnits994581st++;
        if (item.myBillings.m994582ndIsBillable) acc.BillingUnits994582nd++;
        return acc;
      }, { BillingUnits99454: 0, BillingUnits99457: 0, BillingUnits994581st: 0, BillingUnits994582nd: 0 });

      selectedFacilityBillingUnits.userCount = userCount;
      selectedFacilityBillingUnits.BillingUnits99454Percent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits99454 / userCount) * 100).toFixed(1) : 0;
      selectedFacilityBillingUnits.BillingUnits99457Percent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits99457 / userCount) * 100).toFixed(1) : 0;
      selectedFacilityBillingUnits.BillingUnits994581stPercent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits994581st / userCount) * 100).toFixed(1) : 0;
      selectedFacilityBillingUnits.BillingUnits994582ndPercent = (userCount > 0) ? ((selectedFacilityBillingUnits.BillingUnits994582nd / userCount) * 100).toFixed(1) : 0;

      return selectedFacilityBillingUnits;
    },

    populateFacilityGraphData () {
      const distinctHospitalData = this.billingsFromRepo
          .map(item => item.hospitalData)
          .filter((value, index, self) =>
                  value && index === self.findIndex((t) => (
                      t?.id === value?.id && t?.hospitalName === value?.hospitalName
                  )),
          );

      const newData = distinctHospitalData.map(hospital => {
        const hospitalBillingUnits = this.findBillingUnitsbyHospitalId(hospital.id) || {
          BillingUnits99454Percent: 0,
          BillingUnits99457Percent: 0,
          BillingUnits994581stPercent: 0,
          BillingUnits994582ndPercent: 0,
        };

        let result = hospitalBillingUnits.BillingUnits99454Percent * 0.25 +
            hospitalBillingUnits.BillingUnits99457Percent * 0.25 +
            hospitalBillingUnits.BillingUnits994581stPercent * 0.25 +
            hospitalBillingUnits.BillingUnits994582ndPercent * 0.25;

        if (isNaN(result)) result = 0;

        return {
          name: hospital.hospitalName,
          data: result,
        };
      });

      const seriesData = {
        name: this.$t('revenue'),
        data: newData.map(item => item.data),
      };

      const categories = newData.map(item => item.name);

      this.graphSeriesOrganization = [seriesData];
      if (!this.chartOptionsOrganization) {
        this.chartOptionsOrganization = {};
      }
      if (!this.chartOptionsOrganization.xaxis) {
        this.chartOptionsOrganization.xaxis = {};
      }
      if (!this.chartOptionsOrganization.yaxis) {
        this.chartOptionsOrganization.yaxis = {};
      }
      if (!this.chartOptionsOrganization.yaxis.labels) {
        this.chartOptionsOrganization.yaxis.labels = {};
      }

      this.chartOptionsOrganization.xaxis.categories = categories;

      // Update y-axis formatter to display percentages
      this.chartOptionsOrganization.yaxis.labels.formatter = function (value) {
        return `${value.toFixed(0)}%`;
      };

      this.$refs.graphSeriesOrganization.updateSeries(this.graphSeriesOrganization);
      this.$refs.graphSeriesOrganization.updateOptions(this.chartOptionsOrganization);

      // if (this.$vuetify.breakpoint.width <= 1336 ? this.chartOptionsOrganization.chart.height === 200 : this.chartOptionsOrganization.chart.height === 300);

      if (this.$vuetify.breakpoint.width <= 1336) {
        this.chartOptionsOrganization.chart.height = 250;
      } else {
        this.chartOptionsOrganization.chart.height = 300;
      }
    },

    calculatePatientStatusForOrganization () {
      // Count based on the value of the properties if they are true
      this.organizationUserStatus = this.billingsFromRepo.reduce((acc, item) => {
        if (item.patientData !== null && item.patientData !== undefined) {
          if (item.patientData.status === 1) acc.activeUsers++;
          if (item.patientData.status !== 1 && item.patientData.status !== 99) acc.inactiveUsers++;
          if (item.patientData.status === 99) acc.deceasedUsers++;
        }
        return acc;
      }, { activeUsers: 0, inactiveUsers: 0, deceasedUsers: 0 });
    },
    async expand (item) {
      if (item === this.expanded[0]) this.expanded = [];
      else this.expanded = [item];
    },
    secToMinV2 (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${remainingSeconds}s`;
    },
    handlePatientClick (item) {
      this.patientDataObject = item;
      this.clickedPatient = true;
      this.facilityClicked = false;
    },
    getHospitalSummary (selectedHospitalId) {
      let hospitalId = null;
      if (selectedHospitalId === null) {
        hospitalId = localStorage.getItem('selectedHospitalAssignedId');
      } else {
        hospitalId = selectedHospitalId;
      }

      if (!hospitalId) {
        return null;
      }

      if (hospitalId in this.hospitalData) {
        return this.hospitalData[hospitalId];
      } else {
        return null;
      }
    },
    aggregateData () {
      const aggregatedData = {};

      for (const entry of this.billingsFromRepo) {
        if (entry.id === 0) { break; }
        const hospitalId = entry.hospitalData.id;

        if (!(hospitalId in aggregatedData)) {
          aggregatedData[hospitalId] = {
            counter: 0,
            counter99453: 0,
            percent99453: 0.0,
            counter99454: 0,
            percent99454: 0.0,
            counter99457: 0,
            percent99457: 0.0,
            counter994581st: 0,
            percent994581st: 0.0,
            counter994582nd: 0,
            percent994582nd: 0.0,
          };
        }

        aggregatedData[hospitalId].counter += 1;

        if (entry.cptCode99453?.status === 1) {
          aggregatedData[hospitalId].counter99453 += 1;
        }
        if (entry.cptCode99454?.status === 1) {
          aggregatedData[hospitalId].counter99454 += 1;
        }
        if (entry.cptCode99457?.status === 1) {
          aggregatedData[hospitalId].counter99457 += 1;
        }
        if (entry.cptCode99458First?.status === 1) {
          aggregatedData[hospitalId].counter994581st += 1;
        }
        if (entry.cptCode99458Second?.status === 1) {
          aggregatedData[hospitalId].counter994582nd += 1;
        }
      }

      for (const hospitalId in aggregatedData) {
        const totalCounter = aggregatedData[hospitalId].counter;
        if (totalCounter > 0) {
          aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
        }
      }

      var patientNumber = this.billingsFromRepo.length;

      this.billingSummary.cpt99453 = 0;
      this.billingSummary.cpt99454 = 0;
      this.billingSummary.cpt99457 = 0;
      this.billingSummary.cpt994581st = 0;
      this.billingSummary.cpt994582nd = 0;

      if (patientNumber > 0) {
        this.billingsFromRepo.forEach((patient, index) => {
          if (patient.cptCode99453?.status === 1) {
            this.billingSummary.cpt99453++;
          }
          if (patient.cptCode99454?.status === 1) {
            this.billingSummary.cpt99454++;
          }
          if (patient.cptCode99457?.status === 1) {
            this.billingSummary.cpt99457++;
          }
          if (patient.cptCode99458First?.status === 1) {
            this.billingSummary.cpt994581st++;
          }
          if (patient.cptCode99458Second?.status === 1) {
            this.billingSummary.cpt994582nd++;
          }

          let percent99454 = 0;
          let totalMeasurements99454 = 0;
          let measurement99454IsBillable = false;
          let measurement99457IsBillable = false;
          let measurement994581stIsBillable = false;
          let measurement994582ndIsBillable = false;
          if (patient.cptCode99454 !== null) {
            if (patient.cptCode99454.totalMeasurements < 16) {
              percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
            } else {
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
              percent99454 = 100;
              measurement99454IsBillable = true;
            }
          }

          const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

          let percent99457 = 0;
          let percent994581st = 0;
          let percent994582nd = 0;

          let seconds99457 = 0;
          let seconds994581st = 0;
          let seconds994582nd = 0;

          if (totalSec < 1200) {
            seconds99457 = totalSec;
            percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

            seconds994581st = 0;
            percent994581st = 0;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 1200 || totalSec < 2400) {
            seconds99457 = 1200;
            percent99457 = 100;
            measurement99457IsBillable = true;

            seconds994581st = totalSec - 1200;
            percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 2400 || totalSec < 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;
            measurement994581stIsBillable = true;

            seconds994582nd = totalSec - 2400;
            percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
          }

          if (totalSec >= 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;

            seconds994582nd = totalSec - 2400;
            percent994582nd = 100;
            measurement994582ndIsBillable = true;
          }
          const myBillings = {
            totalMeasurements99454: totalMeasurements99454,
            percent99454: percent99454,
            percent99457: percent99457,
            percent994581st: percent994581st,
            percent994582nd: percent994582nd,
            seconds99457: seconds99457,
            seconds994581st: seconds994581st,
            seconds994582nd: seconds994582nd,
            m99454IsBillable: measurement99454IsBillable,
            m99457IsBillable: measurement99457IsBillable,
            m994581stIsBillable: measurement994581stIsBillable,
            m994582ndIsBillable: measurement994582ndIsBillable,
          };
          patient.myBillings = myBillings;
        });
        this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
      }
      this.hospitalData = aggregatedData;
      this.calculateBillingUnitsForOrganization();
      this.calculatePatientStatusForOrganization();
      this.populateFacilityGraphData();
    },
    formatDate (val) {
      // Parse the input date string
      const dateObject = new Date(val);
      // Format the date as "Month Day Year"
      return dateObject.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    },
    calculateAge (dateString) {
      var today = new Date();
      var birthDate = new Date(dateString?.split('T')[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async getbillingsummaryforallpatientsformonth () {
      let year, month;
      if (this.selectedMonthAndYear) {
        [year, month] = this.selectedMonthAndYear.split('-');
      } else {
        const today = new Date();
        year = today.getFullYear();
        month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      }
      if (this.roleName === 'ORGANIZATION_ADMIN') {
        const headers = {
          organizationId: this.authUserData.organizationId,
          month: month,
          year: year,
        };
        this.loader = true;
        await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
          this.aggregateData();
          this.loader = false;
        });
      }
        this.toggleHeaders();
    },
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const openCache = table?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, this.clickedPatient);
          });
        }
      }
      const measurementTable = this.$refs.measurementTable;
      if (measurementTable) {
        const openCache = measurementTable?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, this.clickedPatient);
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
